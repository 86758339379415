.circle-charts-container {
    /* height: 300px; */
    width: 60%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* border: solid red 1px; */
}
.c-c-c-t-text {
    color: white;
    font-size: 24px;
    margin: 0px;
}
.c-c-c-charts {
    overflow: hidden;
    height: 250px;
    width: 100%;
    background-color: white;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    border-top-right-radius: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.c-c-c-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: #e0e0e0 solid 1px;
    height: 100%;
    justify-content: space-evenly;
    width: 100%;
}
.c-c-c-desc {
    text-align: center;
    font-size: 20px;
    color: rgb(0, 99, 66);
}
.c-c-c-c-i-items {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: #334740;
}
.c-c-c-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 200px;
    align-self: flex-end;
    border: solid 1px rgba(97, 120, 113, 0.24);
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    background-color: #00A36C;
    border-bottom: 0px;
    z-index: 1;
    top: -39px;
    position: absolute;
}
.c-c-c-footer {
    width: 80%;
    height: 40px;
    align-self: center;
    position: absolute;
    bottom: -39px;
    z-index: 1;
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: white;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    /* border-top: 0px; */
}