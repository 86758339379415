.searcher {
    height: 40px;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 100px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    background-color: #ffffff;
}
.searcher img {
    height: 20px;
}
.searcher input {
    font-family: 'proximaNovaRegular';
    font-size: 18px!important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #105634b0;
    border: 0px;
    outline: none;
}
.searcher input::placeholder {
    color: #105634b0;    
}