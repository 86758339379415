.button-login {
    width: 346.9px;
    height: 47.1px;
    border-radius: 58px;
    outline: none;
    cursor: pointer;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: "ProximaNovaSemibold";
    box-shadow: inset transparent 2.5px 2.5px 12px -4px rgba(0,0,0,0.75);
    transition: .2s;
}
.button-login img {
    height: 20px;
    margin-left: 10px;
}
.bl-normal {
    background-color: #fc8171;
    color: white;
}
.bl-dis {
    background-color: #d4776a;
}
.button-login:active {
    box-shadow: inset 2.5px 2.5px 12px -4px rgba(0,0,0,0.75);
}
.button-login:hover {
    background-color: #f8624e;
}
.loader_sprite {
    background-image: url('../images/spritesheet_loader.png');
    background-repeat: no-repeat;
    display: block;
    width: 20px;
    height: 20px;
    background-position: 0px 0px;
    background-size: cover;
    animation: animar_loader steps(82) 1.20s infinite;
}
@keyframes animar_loader {
  0%{
    background-position: 0px 0px;
  }
  100%{
    background-position: -1639px 0px;
  }
}