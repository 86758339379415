.login-input {
    height: 55px;
    width: 345px;
    border-bottom: solid 1px #6178718c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s;
}
.l-i-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80px;
    flex-shrink: 0;
}
.l-i-icon img {
    height: 21px;
    width: 16px;
}
.login-input input {
    padding-top: 5px;
    height: 55%;
    width: calc(100% - 80px);
    border: 0px;
    font-family: 'proximaNovaRegular';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d6e6e;
    outline: none;
}
.border-focus {
    border-bottom: solid 1px #6178718c;
}
.svg-focus {
    fill: rgba(121, 206, 173, 0.329);
}