@import url(https://fonts.googleapis.com/css?family=Baloo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@font-face {
    font-family: 'proximaNova';
    src: url("/static/media/Proxima Nova Black.f4013661.otf");
}
@font-face {
    font-family: 'proximaNovaRegular';
    src: url("/static/media/Proxima Nova Regular.410504d4.otf");
}
@font-face {
    font-family: 'ProximaNovaSemibold';
    src: url("/static/media/Proxima Nova Semibold.6a386899.otf");
}
@font-face {
    font-family: 'ProximaNovaExtrabold';
    src: url("/static/media/Proxima Nova Extrabold.b4f9eb8c.otf");
}
@font-face {
    font-family: 'Amino';
    src: url(/static/media/Amino.a1c1f3a2.otf);
}
@font-face {
    font-family: 'AminoBold';
    src: url("/static/media/Amino Medium.a7285617.otf");
}
@font-face {
    font-family: 'AminoAltBold';
    src: url("/static/media/Amino Alt Bold.c6ef2514.otf");
}

.baloo {
    font-family: 'Baloo', cursive;
}
.proximaNova {
    font-family: 'proximaNova';
}
button {
    outline: none;
}
* {
    transition: .2s;
    line-height: normal;
}
.scroll-y::-webkit-scrollbar {
    width: 12px;
    overflow-y: hidden;
}
 
.scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
.scroll-y::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
body {
    /* overflow: hidden; */
    /* overflow-y: hidden; */
    overflow-x: hidden;
}
.react-sweet-progress-symbol {
    display: none!important;
}
button {
    outline: none!important;
}
.content-views {
    width: 80%!important;
    margin: auto;
}
.red {
    border: solid red 1px!important;
}
@-webkit-keyframes animar_loader_grande {
 0%{
   background-position: 0px 0px;
 }
 100%{
   background-position: -15580px 0px;
 }
}
@keyframes animar_loader_grande {
 0%{
   background-position: 0px 0px;
 }
 100%{
   background-position: -15580px 0px;
 }
}
div.loading-content {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 190px;
    height: 190px;
    z-index: 1000;
    margin: auto;
    background-image: url(/static/media/spritesheet_loader.1bfe3515.png);
    background-repeat: no-repeat;
    display: block;
    background-position: 0px 0px;
    background-size: cover;
    -webkit-animation: animar_loader_grande steps(82) 1.20s infinite;
            animation: animar_loader_grande steps(82) 1.20s infinite;
 }
/* @media (min-width: 1200px) {
	.container-d-views {
		max-width: 80%!important;
	}
} */
.modal-opa {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.404);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* z-index: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-nav {
    height: 50px;
}
.button-nav button {
    height: 50px;
    width: 110px;
    border-radius: 45px;
    border: 0px;
    outline: none;
}
.button-nav-inac {
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-family: 'ProximaNovaSemibold';
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.button-nav-ac {
    background-color: white;
    color: #2DA36C;
    font-size: 24px;
    font-family: 'ProximaNovaExtrabold';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00a36c;
}

.profile-d {
    /* min-width: 0px; */
    height: 20px;
}
.profile-d img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px white;
}
.profile-d span {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 18px;
    color: white;
}
.rotated {
    transform: rotate(180deg);
}
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@-webkit-keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}
@keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}
.p-d-menu {
    min-height: 100px;
    width: 200px;
    border-radius: 10px;
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    -webkit-animation: fadeIn .2s;
}
.dis {
    -webkit-animation: fadeIn;
}
.menu-profile {
    height: 100px;
    width: 180px;
    border-radius: 18px;
}
@media (max-width: 600px) {
    .profile-d img {
        display: none;
    }
}
.menu-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
}
.m-p-buttons {
    flex-shrink: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    cursor: pointer;
    background-color: white;
    width: 100%;
    font-family: "ProximaNovaExtrabold";
    font-size: 16px;
    color: #00a36c;
    border-radius: 18px;
}
.m-p-buttons:hover {
    background-color: rgba(45, 163, 108, 0.678);
    color: white;
}
.popover {
    height: 52px!important;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);

}
.navbar-d {
	position: fixed;
	z-index: 2;
	top: 0px;
	right: 0px;
	left: 0px;
    background-color: #2DA36C;
    height: 80px;
	background-image: url(/static/media/bg.19a5c294.png);
	background-position: center;
	background-size: 250px;
}
.spacer-navbar {
    height: 80px;
}
.text-d {
	margin-left: 10px;
    font-family: 'Baloo', cursive;
	font-size: 28px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}
@media (max-width: 600px) {
    .profile-d img, .text-light {
        display: none;
	}
}
.burguer-button {
    /* padding-left: 50px; */
    background-color: transparent;
    border: 0px;
    transition: .2s;
}
.burguer-button img {
    width: 25px;
}
.burguer-button img:active {
    transform: scale(0.9);
}
.menu-popover-d {
    width: 100px;
    min-height: 75px;
    background-color: white;
    display: flex;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
}
.menu-popover-g {
    width: 125px;
    min-height: 50px;
    display: flex;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    flex-direction: column;
    overflow: hidden;
    font-family: "ProximaNovaExtrabold";
    color: #00a36c;
    background-color: white;

}

.popover-menu-button {
    height: 35px;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard-cont {
    width: 100%;
    min-height: 100vh;
	background-image: url(/static/media/bg-2.a94d30e0.png);
	background-position: center;
	background-size: 250px;
}
@media (min-width: 1200px) {
	.container-f-d {
		max-width: 80%!important;
	}
}
.mid-size-l {
    margin: auto;
    width: 80vw;
}
.a-v-header {
    margin-top: 60px;
}
.a-v-header h1 {
    font-family: 'Amino';
    font-size: 42.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: #105634;
}

.section-tabs {
    height: 40px;
    /* min-width: 40px; */
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    display: flex;
    justify-content: flex-start;
    border-bottom: solid 1px #00a36c;
    margin-top: 20px;
    margin-bottom: 60px;
    flex-shrink: 0!important;
}
.s-t-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 60px;
    height: 100%;
    font-family: 'ProximaNovaSemibold';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #71837c;
    margin: 0px 10px;
}
.s-t-active {
    border-bottom: #00a36c solid 3px;
    color: #00a36c!important;
}
.table-task {
    min-width: 150px;
    max-width: 100%;
    min-height: 150px;
    max-height: 600px;
    border-radius: 18px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    background-color: #ffffff;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}
.t-t-header {
    height: 112px;
    width: 100%;
    border-bottom: solid 1px #556b6528;
    display: flex;
    flex-shrink: 0;
}
.t-t-h-nav {
    width: 490px;
    height: 100%;
    border-right: solid 1px #556b6528;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-t-h-options {
    height: 100px;
    width: calc(100% - 490px);
    /* overflow-x: hidden; */
    align-self: flex-end;
    transform: rotateX(180deg);
    display: flex;
    align-items: flex-end;
    overflow-y: hidden;
}
.t-t-h-options * {
    /* transform: rotateX(180deg);  */
}
.t-t-filters {
    height: 40px;
    width: 100%;
    display: flex;
    border-bottom: solid 1px #556b6528;
    flex-shrink: 0;
}
.t-t-content {
    width: 100%;
    overflow: auto;
    display: flex;
}
.t-t-c-alumns {
    width: 490px;
    height: 100%;
    border-right: solid 1px #556b6528;
}
.t-t-c-results {
    width: calc(100% - 490px);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.full-w {
    height: 20px;
    width: calc(100% - 490px);
    flex-shrink: 0;
}
.t-t-f-alumns {
    height: 100%;
    width: 490px;
    border-right: solid 1px #556b6528;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.t-t-f-a-item {
    width: 80px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.t-t-f-a-item img {
    height: 10px;
    width: 10px;
    transform: rotate(270deg);
    cursor: pointer;
}
.t-t-f-a-item-reverse {
    transform: rotate(-270deg)!important;
}
.t-t-f-values {
    width: calc(100% - 490px);
    height: 100%;
    display: flex;
    overflow: hidden;
}
.t-t-h-n-button {
    padding: 10px 20px;
    border-radius: 18px;
    border: solid 1px #cce0d6;
    color: #2DA36C;
}
.alumn-item {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #cce0d6;
    flex-shrink: 0;
}
.a-i-profile {
    width: 318px;
    height: 100%;
    display: flex;
    border-right: solid 1px #cce0d6;
    align-items: center;
}
.a-i-profile img {
    width: 47.5px;
    height: 47.5px;
    border-radius: 50%;
    margin-left: 41px;
    margin-right: 16.5px;
}
.a-i-profile span {
    font-family: 'proximaNovaRegular';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    /* text-align: center; */
    color: #334740; 
}
.a-i-percent {
    width: 64px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    color: #334740;
    border-right: solid 1px #cce0d6;
}
.a-i-status {
    width: calc(100% - 64px - 318px);
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #334740;
}
.a-i-status span {
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    color: #334740ad;
    text-align: center;
    margin: 0;
    width: auto!important;
    padding: 0;
}
.a-i-status img {
    cursor: pointer;
    width: 30px;
}
.a-i-status .react-sweet-progress-circle-outer {
    width: 20px!important;
    height: 20px!important;
}
.a-i-p-name {
    height: 40px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.a-i-p-name span {
    width: calc(50% - 10px);
    margin: 0px 5px;
}
.i-t-header {
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-right: solid 1px #cce0d6;
    flex-shrink: 0;
    transform: rotateX(-180deg);
}
.i-t-h-header {
    min-height: 22px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0px;
}
.t-t-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.t-t-c-active {
    background-color: #1bedff;
}
.t-t-c-inactive {
    background-color: #a4a4a4;
}
.i-t-h-title {
    margin-top: 0 px;
    margin-right: 15px;
    font-family: 'ProximaNovaSemibold';
    font-size: 0.90rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-left: 20px;
    color: #3cc19a;
}
.i-t-h-subtitle {
    font-family: 'ProximaNovaRegular';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.25;
    letter-spacing: normal;
    margin-left: 20px;
    color: #334740;
}
.i-t-h-h-item {
    width: 64px;
    height: 100%;
    background-color: #f4f5f3;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 5px;
    flex-shrink: 0;
}
.i-t-h-h-item span {
    margin-left: 6px;
    font-family: 'ProximaNovaRegular';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.25;
    letter-spacing: normal;
    text-align: right;
    color: #066449;
}
.i-t-content {
    min-width: 200px;
    height: 72px;
    display: flex;
    flex-shrink: 0;
}
.i-t-c-item {
    min-width: 200px;
    height: 100%;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #cce0d6;
    border-bottom: solid 1px #cce0d6;
    font-family: 'ProximaNovaSemiBold';
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    flex-shrink: 0;
}
.i-t-c-item-no-value {
    min-width: 200px;
    height: 100%;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #cce0d6;
    border-bottom: solid 1px #cce0d6;
    font-family: 'ProximaNovaSemiBold';
    font-size: 14px;
    color: #b5b5b5;
}
.filter-comp {
    height: 100%;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-right: solid 1px #cce0d6;
}
.filter-comp img {
    height: 10px;
    transform: rotate(90deg);
    cursor: pointer;
}
.f-c-reverse {
    transform: rotate(270deg)!important;
}
.hv-header {
    margin-top: 60px;
}
.hv-title h1 {
    font-family: 'Amino';
    font-size: 42.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #105634;
}
.modal-content {
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}
.i-c-a-full {
    width: 100%;
    height: 100%;
}
.info-card-assignament {
    width: 375px;
    height: 425px;
    border-radius: 18px;
    margin: 25px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    overflow: auto;
    background-color: white;
    overflow: hidden;
}
.i-c-a-header {
    height: 60px;
    width: 100%;
    background-color: #d8d8d825!important;
    border-bottom: #ECEEEE solid 1px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.i-c-a-header h1 {
    font-size: 20px;
    font-family: 'Roboto';
    color: #497062;
    text-align: center;
    margin: 0px;
}
.i-c-a-content {
    overflow: hidden;
    overflow-y: auto;
    width: calc(100% - 10px);
    height: calc(100% - 75px);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.i-c-a-icon {
    height: 15px;
    cursor: pointer;
}
.i-c-a-h-span {
    font-family: 'proximaNovaRegular';
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #31AD7A;
    border-radius: 50%;
    border: solid #31AD7A 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}
.i-c-a-h-r-span {
    font-family: 'proximaNovaRegular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #EEADA5;
    border-radius: 50%;
    border: solid #EEADA5 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;    
}
.info-alumn-item {
    width: 100%;
    height: 50px;
    border-radius: 18px;
    border: #ECEEEE solid 1px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    overflow: auto;
    flex-shrink: 0;
    overflow: hidden;
}
.i-a-i-item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.i-a-i-i-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.i-a-i-i-title {
    margin: 0px;
    padding: 0px;
    width: 100px;
    font-size: 12px;
    color: #3c5848;
}
.i-a-i-i-span {
    color: #3c5848;
}
.i-a-i-i-prm {
    color: #3c5848;
}
.assignament-table {
    width: 100%;
    min-height: 150px;
    max-height: 600px;
    border-radius: 18px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    margin-bottom: 60px;
}
.a-t-header {
    height: 50px;
    border-bottom: #ECEEEE solid 2px;
    background-color: #FBFBFB;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.a-t-header div {
    width: 33.33%;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 2.5px 5px;
}
.at-h-first {
    display: flex;
    justify-content: center;
    align-items: center;
}
.at-h-second {
    display: flex;
    justify-content: space-around!important;
    align-items: center;
}
.at-h-last {
    justify-content: space-around!important;
}
.a-t-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 50px);
}

.a-t-filter {
    flex-shrink: 0;
    width: auto!important;
}
.a-t-filter span {
    padding-right: 6px;
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #334740;
}
.a-t-filter img {
    width: 20px;
    height: 13px;
    cursor: pointer;
    transition: .2s;
}
.rotate {
    transform: rotate(180deg);
}

.assignaments-table-item {
    width: 100%;
    height: 90px;
    display: flex;
    border-bottom: #ECEEEE solid 1px;
    flex-shrink: 0;
}
.assignaments-table-item div {
    width: 33.33%;
    height: 100%;
}
.a-t-i-first {
    display: flex;
    align-items: center;
    font-size: 18.6px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #5c6d65;
}
.a-t-i-first img {
    margin-left: 36px;
    margin-right: 20px;
}
.a-t-i-second {
    display: flex;
    align-items: center;
    justify-content: space-around!important;

}
.i-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.a-t-i-s-status {
    height: 20px!important;
    width: auto!important;
    display: flex;
    align-items: center;
    /* padding-right: 10px; */
}
.a-t-i-s-circle {
    background-color: #a4a4a4;
    border-radius: 50%;
    height: 10px!important;
    width: 10px!important;
    margin-right: 8px;
}
.info-text-item {
    font-size: 14px;
    text-align: center;
    color: #334740af;
    font-family: 'proximaNovaRegular';
}
.CircularProgressbar {
    width: 18px!important;

}
.CircularProgressbar-path {
    stroke: #f49184!important;
}
.a-t-i-last {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.a-t-i-last div {
    height: 20px;
    width: auto;
}
.a-t-i-last span {
    font-family: 'proximaNovaRegular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #334740af;
}
.CircularProgressbar  {
    margin-right: 10px;
}
.a-t-i-inactive {
    background-color: #d8d8d825!important;
}
.atisc-active {
    background-color: #1bedff;
}
.a-t-i-l-button {
    width: 180px;
    height: 40px;
    border-radius: 120px;
    border: solid 1px rgba(0, 171, 117, 0.24);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ProximaNovaSemibold';
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #00ab75;
}
.press {
    background-color: #00ab75;
    color: white;
}
.a-t-i-collapse {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    overflow: auto;
    width: 100%;
    overflow: hidden;
}
.auto-size {
    height: auto!important;
    width: auto!important;
}
.p-i-r-item {
    width: 238px;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 20px 20px;
    margin-bottom: 10px;
    border-radius: 30px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    font-size: 12px;
    font-family: 'proximaNovaRegular';
}
.p-i-r-i-img {
    width: 450px;
}
.icon-link {
    width: 10px;
    cursor: pointer;
}
.border-right-divide {
    border-right: solid 0.7px #33474054;

}
.border-left-divide {
    border-left: solid 0.7px #33474054;
}
.p-i-r-data {
    display: flex;
    border-radius: 10px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    width: 238px;
    height: 80px;
    margin-bottom: 20px;
    overflow: hidden;
    flex-flow: row;
    flex-wrap: nowrap;
}
.p-i-r-d-item {
    width: 100%;
    height: 100%;
}
.p-i-r-d-item {
    font-size: 12px;
    color: #334740af;
    font-family: 'proximaNovaRegular';
    display: flex;
    flex-direction: column;
    align-items: center;
}
.p-i-r-d-i-header {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.p-i-r-d-i-header img {
    width: 10px;
}
.p-i-r-d-i-header span {
    padding: 0px!important;
    margin-top: 5px;
    font-size: 13px;
    text-align: center;
}
.span-val {
    color: #31AD7A;
    font-size: 16px!important;
}
.little-title {
    font-size: 12px!important;
}
.press-hov {
    cursor: pointer;
}
.press-hov:hover {
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    background-color: #CCE0D6;
    /* border: solid 1px #cce0d6; */
}
.button-small-size {
    height: 30px;
    width: 100px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    border-radius: 18px;
    color: #334740af;
    font-family: 'proximaNovaRegular';
    flex-shrink: 0;
    margin: 5px 5px;
}
.onpress-bss {
    border: solid 1px white;
    background: #cce0d6;
    color: white;
}
.alumn-profile-cont {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
}
.a-p-v-header img {
    border-radius: 50%;
    padding: 15px;
    margin-right: 40px;
    border: solid 1px #cce0d6;
    height: 106px;
    width: 106px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    flex-shrink: 0;
}
.a-p-v-h-navbar {
    border-bottom: solid 1px #cce0d6;
    width: calc(100% - 136px);
    min-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.a-p-v-h-n-item {
    display: flex;
    align-items: center;
    min-width: 200px;
    min-height: 30px;
}
.a-p-v-h-n-item h1 {
    font-size: 42.6px;
    text-align: center;
    color: #105634;
    font-family: 'AminoBold';
    margin: 0!important;
}
.a-p-v-h-n-select-alumn {
    display: flex;
    height: 70px;
}
.a-p-v-cards {
    margin-top: 60px;
}
.container-d-v-profile {
    height: 250px;
    width: 1005;
}
.container-d-v-profile-chart {
    margin-top: 90px;
}
.spacer {
    margin: 0px 20px;
}
.circle-charts-container {
    /* height: 300px; */
    width: 60%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* border: solid red 1px; */
}
.c-c-c-t-text {
    color: white;
    font-size: 24px;
    margin: 0px;
}
.c-c-c-charts {
    overflow: hidden;
    height: 250px;
    width: 100%;
    background-color: white;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    border-top-right-radius: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.c-c-c-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: #e0e0e0 solid 1px;
    height: 100%;
    justify-content: space-evenly;
    width: 100%;
}
.c-c-c-desc {
    text-align: center;
    font-size: 20px;
    color: rgb(0, 99, 66);
}
.c-c-c-c-i-items {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: #334740;
}
.c-c-c-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 200px;
    align-self: flex-end;
    border: solid 1px rgba(97, 120, 113, 0.24);
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    background-color: #00A36C;
    border-bottom: 0px;
    z-index: 1;
    top: -39px;
    position: absolute;
}
.c-c-c-footer {
    width: 80%;
    height: 40px;
    align-self: center;
    position: absolute;
    bottom: -39px;
    z-index: 1;
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: white;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    /* border-top: 0px; */
}
.circle-with-item {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.c-w-i-h1 {
    width: 100%;
    position: absolute;
    top: 10px;
}
.c-w-i-container {
    height: 30%;
    width: 70%;
    position: absolute;
}
.chart-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.c-c-charts {
    height: 290px;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: white;
    border-top-left-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c-c-tab {
    display: flex;
    height: 40px;
    min-width: 100px;
    max-width: calc(100px * 5);
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: white;
    position: absolute;
    top: -39px;
    border-bottom: 0px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    /* border-bottom-right-radius: 18px; */
    overflow: hidden;
}
.c-c-t-item {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00A36C;
    color: white;
    cursor: pointer;
    transition: .2s;
}
.c-c-t-item:hover {
    background-color: rgb(0, 209, 139);
}
.c-c-t-active {
    background-color: rgb(0, 209, 139);
}
.c-c-t-item span {
    /* background-color: white; */
    font-size: 18px;
}
.dynamic-bar-chart {
    height: 80%;
    width: 90%;
}



.alumn-profile-comp {
    height: 100%;
    width: 300px;
    border-radius: 5px;
    background-color: #00A36C;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
}
.a-p-c-header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 8px 0px;
}
.a-p-c-header h5 {
    margin-bottom: 5px;
    font-size: 17px;
    color: white;
}
.a-p-c-body img {
    width: 85px;
    height: 85px;
    border: solid 2px white;
    padding: 2px;
    border-radius: 50%;
}
.alumn-profile-comp h1 {
    width: 90%;
    margin-top: 10px;
    text-align: center;
    color: white;
    font-size: 26px;
}
.profile-information {
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00A36C;
    border-radius: 18px;
    color: white;
}
.p-i-photo {
    height: 110px;
    width: 110px;
    border: white solid 2px;
    padding: 5px;
    border-radius: 50%;
}
.p-i-info {
    height: 75px;
    width: 80%;
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
}
.p-i-i-items {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-i-i-items-hor {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-i-i-h4 {
    font-size: 16px;
    color: white;

}
.p-i-i-name {
    font-size: 18px;
    color: white;
    text-align: center;
}
.p-i-searchBar {
    margin-bottom: 10px;
    height: 25px;
    width: 170px;
    border-radius: 18px;
    border: solid white 2px;
    align-items: center;
    justify-content: flex-end;
}
.p-i-i {
    margin: 0px 10px;
}
.long-card {
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    border-radius: 18px;
    width: 100%;
    height: 275px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0px;
    margin-top: 40px;
}
.l-c-header {
    height: 45px;
    width: 200px;
    position: absolute;
    top: -45px;
    background-color: #00A36C;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.l-c-header h1 {
    color: white;
    text-align: center;
    /* font-family: 'ProximaNovaExtrabold'; */
    font-size: 22px;
    margin: 0px;
}
.l-c-body {
    width: 85%;
    height: 90%;
    margin: auto;
    /* border: red solid 1px; */
    overflow-x: auto;
    display: flex;
    align-items: center;
}
.homework-information {
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    border: solid 2px #00BD7E;
    height: 230px;
    width: 180px;
    border-radius: 18px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: center;
}
.h-i-header {
    height: 35px;
    /* align-items: center; */
    background-color: #00BD7E;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 0px; */
}
.h-i-header h1 {
    font-family: 'ProximaNovaSemibold';
    color: white;
    font-size: 18px;
    margin: 0px;
}
.h-i-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 35px);
}
.h-i-b-items {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h-i-b-borders {
    border-bottom: solid #00BD7E 2px;
    border-top: solid #00BD7E 2px;
}
.h-i-b-rightBorder {
    border-right: solid #00BD7E 1px;;
}
.h-i-b-i-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h-i-b-span {
    color: #00BD7E;
    font-size: 14px;
    line-height: 0.825;
}
.h-i-b-span-color {
    color: rgb(251, 110, 91);
    font-size: 24px;
}
.h-i-b-span-color-s {
    color: rgb(251, 110, 91);
    font-size: 12px;
}
.block-information {
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    border: solid 2px #00BD7E;
    height: 230px;
    width: 180px;
    border-radius: 18px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: center;
    flex-direction: column;
}
.b-i-header {
    width: 100%;
    height: 35px;
    background-color: #00BD7E;
    display: flex;
    justify-content: center;
    align-items: center;
}
.b-i-header h1 {
    font-family: 'ProximaNovaSemibold';
    color: white;
    font-size: 18px;
    margin: 0px;
}
.b-i-body {
    height: calc(100% - 75px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.b-i-b-image {
    height: 150px;
    width: 108px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 15px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    font-size: 12px;
    font-family: 'proximaNovaRegular';
}
.b-i-b-image img {
    /* width: 260px; */
    height: 200px;
}
.b-i-footer {
    height: 40px;
    width: 100%;
    border-top: #00BD7E 2px solid;
    display: flex;
}
.b-i-f-item {
    width: 330.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.b-i-f-i-border {
    border-left: #00BD7E 2px solid;
    border-right: #00BD7E 2px solid;
}
.b-i-f-i-text {
    color: #00BD7E;
    font-size: 9px;
    margin-top: 2px;
}
.b-i-f-i-value {
    color: rgb(251, 110, 91);
    font-size: 15px;
}
.login-container {
	width: 450px;
	height: 450px;
	border-radius: 18px;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
	background-color: #ffffff;
	margin-top: 69px;
	margin-bottom: 100px;
}
.l-c-title {
	font-family: "AminoAltBold";
	font-size: 35.6px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #005737;
	text-align: center;
	margin-top: 72px;
}
.login-input {
    height: 55px;
    width: 345px;
    border-bottom: solid 1px #6178718c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s;
}
.l-i-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80px;
    flex-shrink: 0;
}
.l-i-icon img {
    height: 21px;
    width: 16px;
}
.login-input input {
    padding-top: 5px;
    height: 55%;
    width: calc(100% - 80px);
    border: 0px;
    font-family: 'proximaNovaRegular';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d6e6e;
    outline: none;
}
.border-focus {
    border-bottom: solid 1px #6178718c;
}
.svg-focus {
    fill: rgba(121, 206, 173, 0.329);
}
.button-login {
    width: 346.9px;
    height: 47.1px;
    border-radius: 58px;
    outline: none;
    cursor: pointer;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-family: "ProximaNovaSemibold";
    box-shadow: inset transparent 2.5px 2.5px 12px -4px rgba(0,0,0,0.75);
    transition: .2s;
}
.button-login img {
    height: 20px;
    margin-left: 10px;
}
.bl-normal {
    background-color: #fc8171;
    color: white;
}
.bl-dis {
    background-color: #d4776a;
}
.button-login:active {
    box-shadow: inset 2.5px 2.5px 12px -4px rgba(0,0,0,0.75);
}
.button-login:hover {
    background-color: #f8624e;
}
.loader_sprite {
    background-image: url(/static/media/spritesheet_loader.1bfe3515.png);
    background-repeat: no-repeat;
    display: block;
    width: 20px;
    height: 20px;
    background-position: 0px 0px;
    background-size: cover;
    -webkit-animation: animar_loader steps(82) 1.20s infinite;
            animation: animar_loader steps(82) 1.20s infinite;
}
@-webkit-keyframes animar_loader {
  0%{
    background-position: 0px 0px;
  }
  100%{
    background-position: -1639px 0px;
  }
}
@keyframes animar_loader {
  0%{
    background-position: 0px 0px;
  }
  100%{
    background-position: -1639px 0px;
  }
}
.row-logo{
	color:#fff;
	font-weight: bold;
	font-size:30px;
	text-align: center;
}
.space-logo{
	margin-top: 69px !important;
}
.circle{
	background-color: #fff;
	width: 60px;
	height: 60px;
	border-radius: 50px;
	margin-right: 20px;
	display: inline-block;
}
.authView {
	min-height: 100vh;
	background-image: url(/static/media/bg.19a5c294.png);
	background-position: center;
	background-size: 250px;
}
.logo{
	width:130px;
	height:62px;
	margin-top:5px;
	margin-left:3px;
}
.admin {
    width: 100%;
    background-image: url(/static/media/bg-2.a94d30e0.png);
    background-position: center;
    background-repeat: repeat;
    background-size: 200px;
}
.arrow-bottom {
    height: 25px;
    width: 25px;
    position: fixed;
    top: 22px;
    left: 35px;
    z-index: 10000;
    cursor: pointer;
}
.layout-list {
    width: 80%;
    max-height: 800vh;
    min-height: 300px;  border-radius: 18px;
    background-color: #FBFBFB;
    margin: auto;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    overflow: hidden;
}
.l-l-header {
    height: 50px;
    width: 100%;
    background-color: rgb(241, 241, 241);
    border-bottom: solid 1px #cce0d6;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
.l-l-content {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 0px;
}
.school-item {
    height: 60px;
    width: 100%;
    border-bottom: solid 1px #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}
.s-i-first {
    min-width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.s-i-first img {
    height: 30px;
    border-radius: 50%;
    border: solid 1px #e7e7e7;
    margin-left: 60px;
    margin-right: 30px;
}
.s-i-first h1 {
    font-family: 'Amino';
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5c6d65;
    margin-bottom: 0px;
}
.s-i-second {
    height: 100%;
    width: 100px;
    font-family: 'proximaNovaRegular';
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00ab75;
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.s-i-last {
    min-width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.s-i-last button {
    font-family: 'proximaNovaRegular';
    color: #00ab75;
    font-size: 14px;
    border-radius: 18px;
    border: 0px;
    border: solid 1px #e7e7e7;
    padding: 5px 15px;
    cursor: pointer;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
}
.press {
    color: white!important;
    background-color: #00ab75!important;
}
.w-auto-d {
    width: 100%;
    display: flex;
    overflow-x: visible;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .school-item {
        justify-content: space-around;
    }
    .s-i-first {
        justify-content: center;
    }
    .s-i-first img, .s-i-second {
        display: none;
    }
}
.teachers-table {
    width: 33.33%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.t-t-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: #e2e2e2 1px solid;
    width: 33.33;
    height: 45px;
    max-height: 150px;
    margin: 0px;
}
.t-t-item span {
    margin: 5px;
    border-radius: 18px;
    color: #00ab75;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
}
* {
    transition: .2s;
}
.searcher {
    height: 40px;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 100px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    background-color: #ffffff;
}
.searcher img {
    height: 20px;
}
.searcher input {
    font-family: 'proximaNovaRegular';
    font-size: 18px!important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #105634b0;
    border: 0px;
    outline: none;
}
.searcher input::-webkit-input-placeholder {
    color: #105634b0;    
}
.searcher input:-ms-input-placeholder {
    color: #105634b0;    
}
.searcher input::-ms-input-placeholder {
    color: #105634b0;    
}
.searcher input::placeholder {
    color: #105634b0;    
}
.coordinator {
    width: 100%;
    min-height: calc(100vh - 80px);
    display: flex;
    background-image: url(/static/media/bg-2.a94d30e0.png);
    background-position: center;
    background-repeat: repeat;
    background-size: 200px;
    overflow-x: hidden!important;
}
.teachers-list {
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: #ffffff;
    min-height: 150px;
    min-width: 400px;
    flex-direction: column;
    align-items: center;
    max-height: 60vh;
    overflow: auto;
}
.teachers-list-item {
    display: flex;
    border-bottom: 0.6px solid rgba(97, 120, 113, 0.24);
    height: 50px;
    width: 100%;
    transition: .2s;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.teachers-list-item:hover {
    background: rgb(235, 235, 235);
}
.teachers-list-item h1 {
    color: #497062;
    font-size: 28px;
    font-weight: 500;
    font-family: Roboto;
    text-align: center;
    margin: 0px;
}
.teachers-list-view {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
