.burguer-button {
    /* padding-left: 50px; */
    background-color: transparent;
    border: 0px;
    transition: .2s;
}
.burguer-button img {
    width: 25px;
}
.burguer-button img:active {
    transform: scale(0.9);
}