.info-alumn-item {
    width: 100%;
    height: 50px;
    border-radius: 18px;
    border: #ECEEEE solid 1px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    overflow: auto;
    flex-shrink: 0;
    overflow: hidden;
}
.i-a-i-item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.i-a-i-i-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.i-a-i-i-title {
    margin: 0px;
    padding: 0px;
    width: 100px;
    font-size: 12px;
    color: #3c5848;
}
.i-a-i-i-span {
    color: #3c5848;
}
.i-a-i-i-prm {
    color: #3c5848;
}