.section-tabs {
    height: 40px;
    /* min-width: 40px; */
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    display: flex;
    justify-content: flex-start;
    border-bottom: solid 1px #00a36c;
    margin-top: 20px;
    margin-bottom: 60px;
    flex-shrink: 0!important;
}
.s-t-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 60px;
    height: 100%;
    font-family: 'ProximaNovaSemibold';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #71837c;
    margin: 0px 10px;
}
.s-t-active {
    border-bottom: #00a36c solid 3px;
    color: #00a36c!important;
}