.button-small-size {
    height: 30px;
    width: 100px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    border-radius: 18px;
    color: #334740af;
    font-family: 'proximaNovaRegular';
    flex-shrink: 0;
    margin: 5px 5px;
}
.onpress-bss {
    border: solid 1px white;
    background: #cce0d6;
    color: white;
}