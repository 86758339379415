.school-item {
    height: 60px;
    width: 100%;
    border-bottom: solid 1px #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}
.s-i-first {
    min-width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.s-i-first img {
    height: 30px;
    border-radius: 50%;
    border: solid 1px #e7e7e7;
    margin-left: 60px;
    margin-right: 30px;
}
.s-i-first h1 {
    font-family: 'Amino';
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5c6d65;
    margin-bottom: 0px;
}
.s-i-second {
    height: 100%;
    width: 100px;
    font-family: 'proximaNovaRegular';
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00ab75;
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.s-i-last {
    min-width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.s-i-last button {
    font-family: 'proximaNovaRegular';
    color: #00ab75;
    font-size: 14px;
    border-radius: 18px;
    border: 0px;
    border: solid 1px #e7e7e7;
    padding: 5px 15px;
    cursor: pointer;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
}
.press {
    color: white!important;
    background-color: #00ab75!important;
}
.w-auto-d {
    width: 100%;
    display: flex;
    overflow-x: visible;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .school-item {
        justify-content: space-around;
    }
    .s-i-first {
        justify-content: center;
    }
    .s-i-first img, .s-i-second {
        display: none;
    }
}