.table-task {
    min-width: 150px;
    max-width: 100%;
    min-height: 150px;
    max-height: 600px;
    border-radius: 18px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    background-color: #ffffff;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}
.t-t-header {
    height: 112px;
    width: 100%;
    border-bottom: solid 1px #556b6528;
    display: flex;
    flex-shrink: 0;
}
.t-t-h-nav {
    width: 490px;
    height: 100%;
    border-right: solid 1px #556b6528;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-t-h-options {
    height: 100px;
    width: calc(100% - 490px);
    /* overflow-x: hidden; */
    align-self: flex-end;
    transform: rotateX(180deg);
    display: flex;
    align-items: flex-end;
    overflow-y: hidden;
}
.t-t-h-options * {
    /* transform: rotateX(180deg);  */
}
.t-t-filters {
    height: 40px;
    width: 100%;
    display: flex;
    border-bottom: solid 1px #556b6528;
    flex-shrink: 0;
}
.t-t-content {
    width: 100%;
    overflow: auto;
    display: flex;
}
.t-t-c-alumns {
    width: 490px;
    height: 100%;
    border-right: solid 1px #556b6528;
}
.t-t-c-results {
    width: calc(100% - 490px);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.full-w {
    height: 20px;
    width: calc(100% - 490px);
    flex-shrink: 0;
}
.t-t-f-alumns {
    height: 100%;
    width: 490px;
    border-right: solid 1px #556b6528;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.t-t-f-a-item {
    width: 80px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.t-t-f-a-item img {
    height: 10px;
    width: 10px;
    transform: rotate(270deg);
    cursor: pointer;
}
.t-t-f-a-item-reverse {
    transform: rotate(-270deg)!important;
}
.t-t-f-values {
    width: calc(100% - 490px);
    height: 100%;
    display: flex;
    overflow: hidden;
}
.t-t-h-n-button {
    padding: 10px 20px;
    border-radius: 18px;
    border: solid 1px #cce0d6;
    color: #2DA36C;
}