
.profile-d {
    /* min-width: 0px; */
    height: 20px;
}
.profile-d img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px white;
}
.profile-d span {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 18px;
    color: white;
}
.rotated {
    transform: rotate(180deg);
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}
.p-d-menu {
    min-height: 100px;
    width: 200px;
    border-radius: 10px;
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    -webkit-animation: fadeIn .2s;
}
.dis {
    -webkit-animation: fadeIn;
}
.menu-profile {
    height: 100px;
    width: 180px;
    border-radius: 18px;
}
@media (max-width: 600px) {
    .profile-d img {
        display: none;
    }
}