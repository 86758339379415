.dashboard-cont {
    width: 100%;
    min-height: 100vh;
	background-image: url('../images/bg-2.png');
	background-position: center;
	background-size: 250px;
}
@media (min-width: 1200px) {
	.container-f-d {
		max-width: 80%!important;
	}
}