.assignaments-table-item {
    width: 100%;
    height: 90px;
    display: flex;
    border-bottom: #ECEEEE solid 1px;
    flex-shrink: 0;
}
.assignaments-table-item div {
    width: 33.33%;
    height: 100%;
}
.a-t-i-first {
    display: flex;
    align-items: center;
    font-size: 18.6px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #5c6d65;
}
.a-t-i-first img {
    margin-left: 36px;
    margin-right: 20px;
}
.a-t-i-second {
    display: flex;
    align-items: center;
    justify-content: space-around!important;

}
.i-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.a-t-i-s-status {
    height: 20px!important;
    width: auto!important;
    display: flex;
    align-items: center;
    /* padding-right: 10px; */
}
.a-t-i-s-circle {
    background-color: #a4a4a4;
    border-radius: 50%;
    height: 10px!important;
    width: 10px!important;
    margin-right: 8px;
}
.info-text-item {
    font-size: 14px;
    text-align: center;
    color: #334740af;
    font-family: 'proximaNovaRegular';
}
.CircularProgressbar {
    width: 18px!important;

}
.CircularProgressbar-path {
    stroke: #f49184!important;
}
.a-t-i-last {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.a-t-i-last div {
    height: 20px;
    width: auto;
}
.a-t-i-last span {
    font-family: 'proximaNovaRegular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #334740af;
}
.CircularProgressbar  {
    margin-right: 10px;
}
.a-t-i-inactive {
    background-color: #d8d8d825!important;
}
.atisc-active {
    background-color: #1bedff;
}
.a-t-i-l-button {
    width: 180px;
    height: 40px;
    border-radius: 120px;
    border: solid 1px rgba(0, 171, 117, 0.24);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ProximaNovaSemibold';
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #00ab75;
}
.press {
    background-color: #00ab75;
    color: white;
}
.a-t-i-collapse {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    overflow: auto;
    width: 100%;
    overflow: hidden;
}
.auto-size {
    height: auto!important;
    width: auto!important;
}