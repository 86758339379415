.assignament-table {
    width: 100%;
    min-height: 150px;
    max-height: 600px;
    border-radius: 18px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    margin-bottom: 60px;
}
.a-t-header {
    height: 50px;
    border-bottom: #ECEEEE solid 2px;
    background-color: #FBFBFB;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.a-t-header div {
    width: 33.33%;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 2.5px 5px;
}
.at-h-first {
    display: flex;
    justify-content: center;
    align-items: center;
}
.at-h-second {
    display: flex;
    justify-content: space-around!important;
    align-items: center;
}
.at-h-last {
    justify-content: space-around!important;
}
.a-t-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100% - 50px);
}
