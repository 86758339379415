.menu-popover-d {
    width: 100px;
    min-height: 75px;
    background-color: white;
    display: flex;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
}
.menu-popover-g {
    width: 125px;
    min-height: 50px;
    display: flex;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    flex-direction: column;
    overflow: hidden;
    font-family: "ProximaNovaExtrabold";
    color: #00a36c;
    background-color: white;

}
