.teachers-table {
    width: 33.33%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.t-t-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: #e2e2e2 1px solid;
    width: 33.33;
    height: 45px;
    max-height: 150px;
    margin: 0px;
}
.t-t-item span {
    margin: 5px;
    border-radius: 18px;
    color: #00ab75;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
}
* {
    transition: .2s;
}