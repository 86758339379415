.teachers-list-item {
    display: flex;
    border-bottom: 0.6px solid rgba(97, 120, 113, 0.24);
    height: 50px;
    width: 100%;
    transition: .2s;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.teachers-list-item:hover {
    background: rgb(235, 235, 235);
}
.teachers-list-item h1 {
    color: #497062;
    font-size: 28px;
    font-weight: 500;
    font-family: Roboto;
    text-align: center;
    margin: 0px;
}