.i-t-header {
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    border-right: solid 1px #cce0d6;
    flex-shrink: 0;
    transform: rotateX(-180deg);
}
.i-t-h-header {
    min-height: 22px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0px;
}
.t-t-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.t-t-c-active {
    background-color: #1bedff;
}
.t-t-c-inactive {
    background-color: #a4a4a4;
}
.i-t-h-title {
    margin-top: 0 px;
    margin-right: 15px;
    font-family: 'ProximaNovaSemibold';
    font-size: 0.90rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-left: 20px;
    color: #3cc19a;
}
.i-t-h-subtitle {
    font-family: 'ProximaNovaRegular';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.25;
    letter-spacing: normal;
    margin-left: 20px;
    color: #334740;
}
.i-t-h-h-item {
    width: 64px;
    height: 100%;
    background-color: #f4f5f3;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 5px;
    flex-shrink: 0;
}
.i-t-h-h-item span {
    margin-left: 6px;
    font-family: 'ProximaNovaRegular';
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.25;
    letter-spacing: normal;
    text-align: right;
    color: #066449;
}