.circle-with-item {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.c-w-i-h1 {
    width: 100%;
    position: absolute;
    top: 10px;
}
.c-w-i-container {
    height: 30%;
    width: 70%;
    position: absolute;
}