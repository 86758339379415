@import url('https://fonts.googleapis.com/css?family=Baloo&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
.baloo {
    font-family: 'Baloo', cursive;
}
.proximaNova {
    font-family: 'proximaNova';
}
button {
    outline: none;
}
* {
    transition: .2s;
    line-height: normal;
}
.scroll-y::-webkit-scrollbar {
    width: 12px;
    overflow-y: hidden;
}
 
.scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
.scroll-y::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
body {
    /* overflow: hidden; */
    /* overflow-y: hidden; */
    overflow-x: hidden;
}
.react-sweet-progress-symbol {
    display: none!important;
}
button {
    outline: none!important;
}
.content-views {
    width: 80%!important;
    margin: auto;
}
.red {
    border: solid red 1px!important;
}
@keyframes animar_loader_grande {
 0%{
   background-position: 0px 0px;
 }
 100%{
   background-position: -15580px 0px;
 }
}
div.loading-content {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 190px;
    height: 190px;
    z-index: 1000;
    margin: auto;
    background-image: url('../images/spritesheet_loader.png');
    background-repeat: no-repeat;
    display: block;
    background-position: 0px 0px;
    background-size: cover;
    animation: animar_loader_grande steps(82) 1.20s infinite;
 }
/* @media (min-width: 1200px) {
	.container-d-views {
		max-width: 80%!important;
	}
} */