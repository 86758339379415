.long-card {
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    border-radius: 18px;
    width: 100%;
    height: 275px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 0px;
    margin-top: 40px;
}
.l-c-header {
    height: 45px;
    width: 200px;
    position: absolute;
    top: -45px;
    background-color: #00A36C;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.l-c-header h1 {
    color: white;
    text-align: center;
    /* font-family: 'ProximaNovaExtrabold'; */
    font-size: 22px;
    margin: 0px;
}
.l-c-body {
    width: 85%;
    height: 90%;
    margin: auto;
    /* border: red solid 1px; */
    overflow-x: auto;
    display: flex;
    align-items: center;
}