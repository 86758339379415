.a-v-header {
    margin-top: 60px;
}
.a-v-header h1 {
    font-family: 'Amino';
    font-size: 42.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: #105634;
}
