.modal-opa {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.404);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* z-index: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
}