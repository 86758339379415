.navbar-d {
	position: fixed;
	z-index: 2;
	top: 0px;
	right: 0px;
	left: 0px;
    background-color: #2DA36C;
    height: 80px;
	background-image: url('../images/bg.png');
	background-position: center;
	background-size: 250px;
}
.spacer-navbar {
    height: 80px;
}
.text-d {
	margin-left: 10px;
    font-family: 'Baloo', cursive;
	font-size: 28px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}
@media (max-width: 600px) {
    .profile-d img, .text-light {
        display: none;
	}
}