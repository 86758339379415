.homework-information {
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    border: solid 2px #00BD7E;
    height: 230px;
    width: 180px;
    border-radius: 18px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: center;
}
.h-i-header {
    height: 35px;
    /* align-items: center; */
    background-color: #00BD7E;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 0px; */
}
.h-i-header h1 {
    font-family: 'ProximaNovaSemibold';
    color: white;
    font-size: 18px;
    margin: 0px;
}
.h-i-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 35px);
}
.h-i-b-items {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h-i-b-borders {
    border-bottom: solid #00BD7E 2px;
    border-top: solid #00BD7E 2px;
}
.h-i-b-rightBorder {
    border-right: solid #00BD7E 1px;;
}
.h-i-b-i-items {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.h-i-b-span {
    color: #00BD7E;
    font-size: 14px;
    line-height: 0.825;
}
.h-i-b-span-color {
    color: rgb(251, 110, 91);
    font-size: 24px;
}
.h-i-b-span-color-s {
    color: rgb(251, 110, 91);
    font-size: 12px;
}