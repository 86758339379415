.info-card-assignament {
    width: 375px;
    height: 425px;
    border-radius: 18px;
    margin: 25px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    overflow: auto;
    background-color: white;
    overflow: hidden;
}
.i-c-a-header {
    height: 60px;
    width: 100%;
    background-color: #d8d8d825!important;
    border-bottom: #ECEEEE solid 1px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.i-c-a-header h1 {
    font-size: 20px;
    font-family: 'Roboto';
    color: #497062;
    text-align: center;
    margin: 0px;
}
.i-c-a-content {
    overflow: hidden;
    overflow-y: auto;
    width: calc(100% - 10px);
    height: calc(100% - 75px);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.i-c-a-icon {
    height: 15px;
    cursor: pointer;
}
.i-c-a-h-span {
    font-family: 'proximaNovaRegular';
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #31AD7A;
    border-radius: 50%;
    border: solid #31AD7A 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}
.i-c-a-h-r-span {
    font-family: 'proximaNovaRegular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #EEADA5;
    border-radius: 50%;
    border: solid #EEADA5 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;    
}