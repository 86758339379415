.hv-header {
    margin-top: 60px;
}
.hv-title h1 {
    font-family: 'Amino';
    font-size: 42.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #105634;
}
.modal-content {
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}
.i-c-a-full {
    width: 100%;
    height: 100%;
}