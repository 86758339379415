.alumn-profile-cont {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
}
.a-p-v-header img {
    border-radius: 50%;
    padding: 15px;
    margin-right: 40px;
    border: solid 1px #cce0d6;
    height: 106px;
    width: 106px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    flex-shrink: 0;
}
.a-p-v-h-navbar {
    border-bottom: solid 1px #cce0d6;
    width: calc(100% - 136px);
    min-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.a-p-v-h-n-item {
    display: flex;
    align-items: center;
    min-width: 200px;
    min-height: 30px;
}
.a-p-v-h-n-item h1 {
    font-size: 42.6px;
    text-align: center;
    color: #105634;
    font-family: 'AminoBold';
    margin: 0!important;
}
.a-p-v-h-n-select-alumn {
    display: flex;
    height: 70px;
}
.a-p-v-cards {
    margin-top: 60px;
}
.container-d-v-profile {
    height: 250px;
    width: 1005;
}
.container-d-v-profile-chart {
    margin-top: 90px;
}
.spacer {
    margin: 0px 20px;
}