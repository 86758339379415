.chart-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.c-c-charts {
    height: 290px;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: white;
    border-top-left-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c-c-tab {
    display: flex;
    height: 40px;
    min-width: 100px;
    max-width: calc(100px * 5);
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: white;
    position: absolute;
    top: -39px;
    border-bottom: 0px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    /* border-bottom-right-radius: 18px; */
    overflow: hidden;
}
.c-c-t-item {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00A36C;
    color: white;
    cursor: pointer;
    transition: .2s;
}
.c-c-t-item:hover {
    background-color: rgb(0, 209, 139);
}
.c-c-t-active {
    background-color: rgb(0, 209, 139);
}
.c-c-t-item span {
    /* background-color: white; */
    font-size: 18px;
}