.p-i-r-item {
    width: 238px;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 20px 20px;
    margin-bottom: 10px;
    border-radius: 30px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    font-size: 12px;
    font-family: 'proximaNovaRegular';
}
.p-i-r-i-img {
    width: 450px;
}
.icon-link {
    width: 10px;
    cursor: pointer;
}
.border-right-divide {
    border-right: solid 0.7px #33474054;

}
.border-left-divide {
    border-left: solid 0.7px #33474054;
}
.p-i-r-data {
    display: flex;
    border-radius: 10px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    width: 238px;
    height: 80px;
    margin-bottom: 20px;
    overflow: hidden;
    flex-flow: row;
    flex-wrap: nowrap;
}
.p-i-r-d-item {
    width: 100%;
    height: 100%;
}
.p-i-r-d-item {
    font-size: 12px;
    color: #334740af;
    font-family: 'proximaNovaRegular';
    display: flex;
    flex-direction: column;
    align-items: center;
}
.p-i-r-d-i-header {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.p-i-r-d-i-header img {
    width: 10px;
}
.p-i-r-d-i-header span {
    padding: 0px!important;
    margin-top: 5px;
    font-size: 13px;
    text-align: center;
}
.span-val {
    color: #31AD7A;
    font-size: 16px!important;
}
.little-title {
    font-size: 12px!important;
}
.press-hov {
    cursor: pointer;
}
.press-hov:hover {
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    background-color: #CCE0D6;
    /* border: solid 1px #cce0d6; */
}