@font-face {
    font-family: 'proximaNova';
    src: url('../fonts/Proxima Nova Black.otf');
}
@font-face {
    font-family: 'proximaNovaRegular';
    src: url('../fonts/Proxima Nova Regular.otf');
}
@font-face {
    font-family: 'ProximaNovaSemibold';
    src: url('../fonts/Proxima Nova Semibold.otf');
}
@font-face {
    font-family: 'ProximaNovaExtrabold';
    src: url('../fonts/Proxima Nova Extrabold.otf');
}
@font-face {
    font-family: 'Amino';
    src: url('../fonts/Amino.otf');
}
@font-face {
    font-family: 'AminoBold';
    src: url('../fonts/Amino Medium.otf');
}
@font-face {
    font-family: 'AminoAltBold';
    src: url('../fonts/Amino Alt Bold.otf');
}
