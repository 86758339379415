.a-t-filter {
    flex-shrink: 0;
    width: auto!important;
}
.a-t-filter span {
    padding-right: 6px;
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #334740;
}
.a-t-filter img {
    width: 20px;
    height: 13px;
    cursor: pointer;
    transition: .2s;
}
.rotate {
    transform: rotate(180deg);
}
