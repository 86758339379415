.layout-list {
    width: 80%;
    max-height: 800vh;
    min-height: 300px;  border-radius: 18px;
    background-color: #FBFBFB;
    margin: auto;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    overflow: hidden;
}
.l-l-header {
    height: 50px;
    width: 100%;
    background-color: rgb(241, 241, 241);
    border-bottom: solid 1px #cce0d6;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
.l-l-content {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 0px;
}