.button-nav {
    height: 50px;
}
.button-nav button {
    height: 50px;
    width: 110px;
    border-radius: 45px;
    border: 0px;
    outline: none;
}
.button-nav-inac {
    background-color: transparent;
    color: white;
    font-size: 20px;
    font-family: 'ProximaNovaSemibold';
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.button-nav-ac {
    background-color: white;
    color: #2DA36C;
    font-size: 24px;
    font-family: 'ProximaNovaExtrabold';
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #00a36c;
}