.menu-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
}
.m-p-buttons {
    flex-shrink: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    cursor: pointer;
    background-color: white;
    width: 100%;
    font-family: "ProximaNovaExtrabold";
    font-size: 16px;
    color: #00a36c;
    border-radius: 18px;
}
.m-p-buttons:hover {
    background-color: rgba(45, 163, 108, 0.678);
    color: white;
}
.popover {
    height: 52px!important;
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);

}