.teachers-list {
    border-radius: 18px;
    border: solid 1px rgba(97, 120, 113, 0.24);
    background-color: #ffffff;
    min-height: 150px;
    min-width: 400px;
    flex-direction: column;
    align-items: center;
    max-height: 60vh;
    overflow: auto;
}