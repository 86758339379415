.row-logo{
	color:#fff;
	font-weight: bold;
	font-size:30px;
	text-align: center;
}
.space-logo{
	margin-top: 69px !important;
}
.circle{
	background-color: #fff;
	width: 60px;
	height: 60px;
	border-radius: 50px;
	margin-right: 20px;
	display: inline-block;
}
.authView {
	min-height: 100vh;
	background-image: url('../images/bg.png');
	background-position: center;
	background-size: 250px;
}
.logo{
	width:130px;
	height:62px;
	margin-top:5px;
	margin-left:3px;
}