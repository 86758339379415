.alumn-item {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #cce0d6;
    flex-shrink: 0;
}
.a-i-profile {
    width: 318px;
    height: 100%;
    display: flex;
    border-right: solid 1px #cce0d6;
    align-items: center;
}
.a-i-profile img {
    width: 47.5px;
    height: 47.5px;
    border-radius: 50%;
    margin-left: 41px;
    margin-right: 16.5px;
}
.a-i-profile span {
    font-family: 'proximaNovaRegular';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    /* text-align: center; */
    color: #334740; 
}
.a-i-percent {
    width: 64px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    color: #334740;
    border-right: solid 1px #cce0d6;
}
.a-i-status {
    width: calc(100% - 64px - 318px);
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #334740;
}
.a-i-status span {
    font-family: 'ProximaNovaSemibold';
    font-size: 14px;
    font-weight: 600;
    color: #334740ad;
    text-align: center;
    margin: 0;
    width: auto!important;
    padding: 0;
}
.a-i-status img {
    cursor: pointer;
    width: 30px;
}
.a-i-status .react-sweet-progress-circle-outer {
    width: 20px!important;
    height: 20px!important;
}
.a-i-p-name {
    height: 40px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.a-i-p-name span {
    width: calc(50% - 10px);
    margin: 0px 5px;
}