.block-information {
    box-shadow: 0px 0px 8px -4px rgba(0,0,0,1);
    border: solid 2px #00BD7E;
    height: 230px;
    width: 180px;
    border-radius: 18px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: center;
    flex-direction: column;
}
.b-i-header {
    width: 100%;
    height: 35px;
    background-color: #00BD7E;
    display: flex;
    justify-content: center;
    align-items: center;
}
.b-i-header h1 {
    font-family: 'ProximaNovaSemibold';
    color: white;
    font-size: 18px;
    margin: 0px;
}
.b-i-body {
    height: calc(100% - 75px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.b-i-b-image {
    height: 150px;
    width: 108px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 15px;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
    font-size: 12px;
    font-family: 'proximaNovaRegular';
}
.b-i-b-image img {
    /* width: 260px; */
    height: 200px;
}
.b-i-footer {
    height: 40px;
    width: 100%;
    border-top: #00BD7E 2px solid;
    display: flex;
}
.b-i-f-item {
    width: 330.33%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.b-i-f-i-border {
    border-left: #00BD7E 2px solid;
    border-right: #00BD7E 2px solid;
}
.b-i-f-i-text {
    color: #00BD7E;
    font-size: 9px;
    margin-top: 2px;
}
.b-i-f-i-value {
    color: rgb(251, 110, 91);
    font-size: 15px;
}