.login-container {
	width: 450px;
	height: 450px;
	border-radius: 18px;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
	background-color: #ffffff;
	margin-top: 69px;
	margin-bottom: 100px;
}
.l-c-title {
	font-family: "AminoAltBold";
	font-size: 35.6px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #005737;
	text-align: center;
	margin-top: 72px;
}