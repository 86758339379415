.profile-information {
    height: 250px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00A36C;
    border-radius: 18px;
    color: white;
}
.p-i-photo {
    height: 110px;
    width: 110px;
    border: white solid 2px;
    padding: 5px;
    border-radius: 50%;
}
.p-i-info {
    height: 75px;
    width: 80%;
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
}
.p-i-i-items {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-i-i-items-hor {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-i-i-h4 {
    font-size: 16px;
    color: white;

}
.p-i-i-name {
    font-size: 18px;
    color: white;
    text-align: center;
}
.p-i-searchBar {
    margin-bottom: 10px;
    height: 25px;
    width: 170px;
    border-radius: 18px;
    border: solid white 2px;
    align-items: center;
    justify-content: flex-end;
}
.p-i-i {
    margin: 0px 10px;
}