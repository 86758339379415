.i-t-content {
    min-width: 200px;
    height: 72px;
    display: flex;
    flex-shrink: 0;
}
.i-t-c-item {
    min-width: 200px;
    height: 100%;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #cce0d6;
    border-bottom: solid 1px #cce0d6;
    font-family: 'ProximaNovaSemiBold';
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    flex-shrink: 0;
}
.i-t-c-item-no-value {
    min-width: 200px;
    height: 100%;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #cce0d6;
    border-bottom: solid 1px #cce0d6;
    font-family: 'ProximaNovaSemiBold';
    font-size: 14px;
    color: #b5b5b5;
}