.alumn-profile-comp {
    height: 100%;
    width: 300px;
    border-radius: 5px;
    background-color: #00A36C;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 10px 0 rgba(3, 50, 34, 0.04);
    border: solid 1px #cce0d6;
}
.a-p-c-header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 8px 0px;
}
.a-p-c-header h5 {
    margin-bottom: 5px;
    font-size: 17px;
    color: white;
}
.a-p-c-body img {
    width: 85px;
    height: 85px;
    border: solid 2px white;
    padding: 2px;
    border-radius: 50%;
}
.alumn-profile-comp h1 {
    width: 90%;
    margin-top: 10px;
    text-align: center;
    color: white;
    font-size: 26px;
}