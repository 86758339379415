.filter-comp {
    height: 100%;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-right: solid 1px #cce0d6;
}
.filter-comp img {
    height: 10px;
    transform: rotate(90deg);
    cursor: pointer;
}
.f-c-reverse {
    transform: rotate(270deg)!important;
}